<template>
  <div>
    <NavBar :notificationsCount="notificationsCount" v-if="this.$route.name !== 'Login' && this.$route.name !== 'TwoFactor'" />
    <router-view />
    <b-container fluid class="pt-0">
      <b-row align-h="end">
        <b-col cols="12" lg="12" align-self="center" class="text-left mt-3 mb-4 pl-4" v-bind:class="{ 'text-center': this.$route.name === 'Login', 'text-left': !this.$route.name === 'Login' }"><a href="https://adfluential.com" target="_blank" style="color: #000000; text-decoration: none">&copy; Adfluential Limited 2024</a></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "./components/NavBar";
import store from "./store";
import axios from "axios";
import moment from "moment";

export default {
  name: "App",
  components: {
    NavBar,
  },
  data() {
    return {
      toastCount: 0,
      userUuid: store.getters.userUuid,
      messagesShown: [],
      notificationsCount: 0,
    };
  },
  methods: {
    // async checkNotifications() {
    //   setInterval(async () => {
    //     await this.displayNotifications();
    //   }, 5000);
    // },
    async displayNotifications() {
      await axios.get(`/notification/${this.userUuid}`).then((response) => {
        this.notificationsCount = response.data?.length || 0;
        if (response.data != null && response.data != undefined && response.data?.length > 0) {
          let newMessages = response.data.filter((notification) => notification.notificationRead == false && !this.messagesShown.includes(notification.notificationUuid));
          newMessages = newMessages.slice(0, 5);
          newMessages.forEach((message) => {
          this.messagesShown.push(message.notificationUuid);
          let h = this.$createElement
          let title = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h('strong', { class: 'mr-2' }, message.notificationTitle),
            h('small', { class: 'ml-auto text-italics' }, moment(message.notificationTimestamp).fromNow())
          ]
        );
          this.$bvToast.toast(message.notificationMessage, {
            title: [title],
            variant: message.notificationType,
            solid: true,
            noAutoHide: true,
            appendToast: true,
            id: message.notificationUuid,
            toaster: "b-toaster-bottom-right",
            });
          });
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      this.displayNotifications();
    }, 5000);
    // this.$root.$on("bv::toast::hidden", (event) => {
    //   axios
    //     .put(`/notification/update/${event.componentId}`, {
    //       notificationRead: true,
    //     })
    //     .then((response) => {
    //         console.log(response.data);
    //     });
    // });
  },
};
</script>

<style lang="scss">
html {
  font-size: 14px;
}
.toast {
  font-size: 1rem !important;
}
</style>
